import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Container,
  IconButton,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useHttp } from '../hooks/useHttp/useHttp';
import { FORGOT_PASSWORD_PATH } from '../variables/endpoints';
import { useAsyncFunction } from '../hooks/useAsyncFunction';
import './Login.scss';
import CloseIcon from '@mui/icons-material/Close';
import { ApiMethods } from '../variables/enums';

const RootStyle = styled('div')({
  height: '100vh',
  display: 'grid',
  placeItems: 'center',
});

const HeadingStyle = styled(Box)({
  textAlign: 'center',
});

const ContentStyle = styled('div')({
  maxWidth: 480,
  padding: 25,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  background: '#fff',
});

function ForgetPassword() {
  const [UserEmail, setUserEmail] = useState<string>('');
  const { loading, error, setError, data, setData, call } = useAsyncFunction();
  const { api } = useHttp();

  const handleForgetPassword = async () => {
    call(() =>
      api({
        method: ApiMethods.PUT,
        url: FORGOT_PASSWORD_PATH,
        data: { email: UserEmail, fromOrigin: true },
      }),
    );
  };

  return (
    <RootStyle className="login-page">
      <Container maxWidth="sm">
        <Paper elevation={3} sx={{ width: '100%' }}>
          <ContentStyle>
            <HeadingStyle>
              <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                Forgot your password
              </Typography>
            </HeadingStyle>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                }}
              >
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="email"
                  label="Email Address"
                  onChange={(e) => setUserEmail(e.target.value)}
                  value={UserEmail}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ my: 4 }}
                >
                  <Link
                    component={RouterLink}
                    variant="mediumMedium"
                    to="/login"
                    underline="hover"
                  >
                    Login to your account
                  </Link>
                </Stack>
                <Button
                  disabled={loading}
                  fullWidth
                  size="large"
                  type="submit"
                  sx={{ my: 2, display: 'block', padding: 3 }}
                  variant="contained"
                  onClick={handleForgetPassword}
                >
                  {loading ? <CircularProgress size={16} /> : 'Forgot Password'}
                </Button>
              </Box>
            </Box>
            <Collapse in={!!error}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    size="small"
                    onClick={() => {
                      setError(undefined);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                <AlertTitle>Error</AlertTitle>
                {error?.message}
              </Alert>
            </Collapse>
            <Collapse in={!!data}>
              <Alert
                severity="success"
                action={
                  <IconButton
                    size="small"
                    onClick={() => {
                      setData(undefined);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                <AlertTitle>Success</AlertTitle>
                {data?.data?.message}
              </Alert>
            </Collapse>
          </ContentStyle>
        </Paper>
      </Container>
    </RootStyle>
  );
}

export default ForgetPassword;
